<template>
    <div>
        <b-button @click="showPartants" class="mr-2" :variant="variantPartants"><font-awesome-icon :icon="['fal', 'binoculars']" /> {{ $t('global.partants') }}</b-button>
        <b-button @click="showResults"  :variant="variantResults"><font-awesome-icon :icon="['fal', 'flag-checkered']" /> {{ $t('global.resultats') }}</b-button>

        <div class="mt-3">
            <Perfs   v-show="cmp == 'partants'" :horse_id="horse_id" />
            <Results v-show="cmp == 'results'"  :horse_id="horse_id" />
        </div>
    </div>
</template>


<script type="text/javascript">
	export default {
		name: "HorsePerfs",
        components: {
			Perfs: () => import('@/components/Dedicated/Acts/Perfs'),
			Results: () => import('@/components/Dedicated/Acts/Results')
        },
        props: {
            horse_id: { type: Number, default: 0 }
        },
        data() {
            return {
                cmp: 'partants'
            }
        },
        methods: {
            showPartants() {
                this.cmp = 'partants'
            },
            showResults() {
                this.cmp = 'results'
            }
        },
        computed: {
            variantPartants() {
                if(this.cmp == 'partants') return 'primary'
                return 'secondary'
            },
            variantResults() {
                if(this.cmp == 'results') return 'primary'
                return 'secondary'
            }
        }
	}
</script>
